import React from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';

class Schedule extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			message: '',
			show: false,
			mailSent: false,
			mailError: false
		}
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
	  }
	  
	handleClose() {
		this.setState({ show: false, mailError: false });
	}
	handleShow() { 
		this.setState({ show: true });
	}
	  
	handleSubmit(e){
		e.preventDefault();
		var that = this;
		axios({
		  method: "POST", 
		  url:"https://us-central1-mail-api-11213.cloudfunctions.net/api/send", 
		  data: this.state
		}).then((response)=>{
		  if (response.data.status === 'success'){
			this.setState({mailSent: true});
			this.resetForm();
		  }
		  else {
			this.setState({mailError: true});
		  }
		})
		.catch(
			function (error) {
				console.log(error);
				that.setState({mailError: true});
			}
		);
	}

	resetForm() {
		this.setState({
			name: '',
			email: '',
			message: '',
			mailError: false
		});
	}

	onNameChange(event) {
		this.setState({name: event.target.value})
	}

	onEmailChange(event) {
		this.setState({email: event.target.value})
	}

	onMessageChange(event) {
		this.setState({message: event.target.value})
	}
	  
  render() {
    return (
    	<>
      <Button className="center" variant="primary" onClick={this.handleShow}>
        Click here to schedule TODAY!
      </Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule a Massage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
			<Alert show={this.state.mailSent} variant="success">
			<Alert.Heading>Success</Alert.Heading>
			<p>
			  Thank you for your inquiry. Someone will respond to your request within 24 hours.
			</p>
		  </Alert>
		  <Alert show={this.state.mailError} variant="danger">
			<Alert.Heading>Error</Alert.Heading>
			<p>
			  There was an error while processing your request. Please try again.
			</p>
		  </Alert>
		  <Alert variant="info">
			<p>
			  Please leave your email address and preferred appointment time and someone will get back to you soon.
			</p>
		  </Alert>
        	<form id="contact-form" onSubmit={this.handleSubmit} method="POST">
				<div className="form-group">
					<label htmlFor="name">Name</label>
					<input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
				</div>
				<div className="form-group">
					<label htmlFor="exampleInputEmail1">Email address</label>
					<input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
				</div>
				<div className="form-group">
					<label htmlFor="message">Message</label>
					<textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
				</div>
				<button type="submit" className="btn btn-primary">Submit</button>
			</form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );
  }
}
 
export default Schedule;