import React from 'react';
import logo from './imgs/self/self01.jpeg';
import { Figure, Col, Row, Container } from 'react-bootstrap';
import Schedule from './schedule';
import Testimonials from './testimonials';

class Therapists extends React.Component {
	render () {
		return (
			<section id="therapists">
				<Container>
				<Row>
        <Col md="3"></Col>
        <Col md="6">
          <Figure className="center">
  					<Figure.Image
    					width={200}
    					alt="patricia"
    					src={logo}
  					/>
  					<Figure.Caption>
    					<strong>Patricia E Utter LMT</strong><br /> 
Call or Text 602-758-1404
    				</Figure.Caption>
				</Figure>
				</Col>
				<Col md="3">
				</Col>
				</Row>
				<Row className="justify-content-sm-center">
				<Col sm="auto">
					<ul className="fa-ul">
					<li><span className="fa-li"><i className="fas fa-map-marker"></i></span><p className="left">7950 E Redfield Rd, Suite #160 <br/> Scottsdale, AZ 85260</p></li>
					</ul>
				</Col>
				</Row>
				<Row>
				<Col lg="12">
				<Testimonials />
				</Col>
				</Row>
				<Row>
				<Col lg="12">
				<Schedule />
				</Col>
				</Row>
      </Container>
      		</section>
    	);
  	}
}

export default Therapists;