import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class Testimonials extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			show: false
		}
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);
	  }
	  
	handleClose() {
		this.setState({ show: false, mailError: false });
	}
	handleShow() { 
		this.setState({ show: true });
	}
	  
  render() {
    return (
    	<>
      <Button className="center" variant="secondary" onClick={this.handleShow}>
        Testimonials
      </Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Testimonials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        	<p className="quote">Massage North Scottsdale is simply <strong><u>the best of the best</u></strong>. Professional, caring, and just the right touch, make every visit to either location rewarding. Body work is critical to improving and maintaining your body&lsquo;s ability to recover from life - Patty is awesome.</p>
        	<p><small>- J Frost, Cyclist</small></p>
        	<p className="quote">Patty at Massage North Scottsdale has been my go to therapist for a couple years now. Her ability to pin point my trouble areas and quickly relieve soreness and tension has allowed me to keep doing high intensity training 5-6 times each week. Before regular treatments, my body would break down and muscle fatigue would prevent me from wanting to train. My husband suffers from chronic lower back pain, and he also sees Patty for more therapeutic work. He’s always relaxed and without pain when he leaves her studio. She does it all, and does it well! I’m so glad to have found her!</p>
        	<p><small>- Christie, Crossfitter</small></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );
  }
}
 
export default Testimonials;