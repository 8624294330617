import React from 'react';
import Logo from './logo.png';
import Home from "./home";
import Benefits from "./benefits";
import Therapists from "./therapists";
import Modalities from "./modalities";
import Contact from "./contact";
import About from "./about";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faThumbsUp, faMapMarkerAlt, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { Nav, Navbar } from 'react-bootstrap';

library.add(faArrowUp, faThumbsUp, faMapMarkerAlt, faCheckSquare);

class ScrollButton extends React.Component {
  	constructor() {
    	super();
    	this.state = {
        	intervalId: 0,
        	isTop: true
    	};
    	this.handleScroll = this.handleScroll.bind(this);
  	}
  
  	componentDidMount() {
  		window.addEventListener('scroll', this.handleScroll);
	};

	componentWillUnmount() {
  		window.removeEventListener('scroll', this.handleScroll);
	};
  
  	handleScroll() {
    	this.setState({
    		isTop: window.scrollY === 0
    	});
	}
	
  	scrollStep() {
    	if (window.pageYOffset === 0) {
        	clearInterval(this.state.intervalId);
    	}
    	window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    	this.setState({
    		isTop: true
    	});
  	}
  
  	scrollToTop() {
    	let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    	this.setState({ intervalId: intervalId });
  	}
  
  	render () {
      return <button 
      			className={this.state.isTop ? 'hidden scroll' : ' scroll'}
      			onClick={ () => { this.scrollToTop(); }} 
      			title="Go to top"
      		>
            <FontAwesomeIcon icon='arrow-up' color='white' />
        </button>;
   	}
} 

class Main extends React.Component {  
  	render () {
    	return (
    		<div className="wrapper">
              	<img
        			src={Logo}
        			height="100"
        			className="center"
        			alt="Massage North Scottsdale"
      			/>
           		<div className="content">
           			<Home />
              		<Benefits />
              		<Therapists />
              		<Modalities />
              		<About />
              		<Contact />
           		</div>
    			<ScrollButton scrollStepInPx="50" delayInMs="16"/>
    			<footer>
    				<Navbar bg="light" variant="light" className="justify-content-center">
						<Nav.Item>
							<small>Patricia E Utter LMT <br />7950 E Redfield Rd Suite 160 Scottsdale, AZ 85260 <br />602-758-1404</small><br />
							<small>Massage North Scottsdale © 2022</small>
						</Nav.Item>
					</Navbar>
					<Navbar bg="light" variant="light" sticky="bottom" className="justify-content-center">
						<Nav.Item>
							<Navbar.Text>
								<a href="https://www.facebook.com/MassageNorthScottsdale/" className="mr-3"><FontAwesomeIcon icon={faFacebook} color="#A69897" /></a>
							</Navbar.Text>
							<Navbar.Text>
								<a href="https://www.instagram.com/explore/locations/731713500209404/massage-north-scottsdale/" className="mr-3"><FontAwesomeIcon icon={faInstagram} color="#A69897" /></a>
							</Navbar.Text>
							<Navbar.Text>
								<a href="https://goo.gl/maps/41JkASchmtvRjQqy5"><FontAwesomeIcon icon="map-marker-alt" color="#A69897" /></a>
							</Navbar.Text>
						</Nav.Item>
					</Navbar>
  				</footer>
        	</div>
  		);
  	}
}
 
export default Main;