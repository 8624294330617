import React from 'react';
import ReactDOM from 'react-dom';
import './css/bootstrap.min.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'; 
import './css/gallery.scss';
import './css/index.scss';
import Main from "./main";


ReactDOM.render(
  <Main/>,
  document.getElementById('root')
);