import React from 'react';
import { Container } from 'react-bootstrap';
import logoNs from './imgs/logos/massage-ns-darkcolor.png';

class Contact extends React.Component {
  render() {
    return (
    <section id="contact">
<Container>
<img
      src={logoNs}
      alt="North Scottsdale"
	  width="100"
	  className="center"
    />
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.655028226277!2d-111.91135398420015!3d33.61425418072746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b756d07fcd9c1%3A0x45e4f81dba1c5d76!2sMassage%20North%20Scottsdale!5e0!3m2!1sen!2sus!4v1615339909881!5m2!1sen!2sus" title="Scottsdale location" width="600" height="450" frameBorder="0" allowFullScreen></iframe>
</Container>
</section>
    );
  }
}
 
export default Contact;