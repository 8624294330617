import React from 'react';
import { Container } from 'react-bootstrap';
import Schedule from './schedule';

class About extends React.Component {
  render() {
    return (
    <section id="about">
<Container>
<p>
10 years of experience working alongside chiropractors,<br />accident injuries and athletes from practically every sport <br />for both injury and performance related issues.
</p>
<p>
If you work out, play a sport, or walk your dog,<br />if your job is physical or static,<br />if you drive too much, if you’re taking care of family, <br />or if life is just stressing you out<br />
<strong>YOU NEED MASSAGE!</strong>
</p>
<Schedule />
</Container>
</section>
    );
  }
}
 
export default About;