import React from 'react';
import gallery01 from './imgs/gallery/gallery01.jpg';
import gallery02 from './imgs/gallery/gallery02.jpg';
import gallery03 from './imgs/gallery/gallery03.jpg';
import abmpLogo from './imgs/logos/ABMP_RM_Round_Full.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import Schedule from './schedule';

class Home extends React.Component {
  render() {
    return (
    <section id="home">
    	<Carousel>
  <Carousel.Item>
    <img
      className="d-block"
      src={gallery01}
      alt="Location"
    />
    <Carousel.Caption className="dark"> 
	<h3>
	Welcome to Massage North Scottsdale!
	</h3>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block"
      src={gallery02}
      alt="Relaxation flowers"
    />
    <Carousel.Caption>
      <h3>Benefits of Massage</h3>
      <p>Relax, recover, restore!  Increase performance, improve recovery time, promote circulation, relieve muscle tension and reduce fatigue and stress.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block"
      src={gallery03}
      alt="Desert petals"
    />
    <Carousel.Caption className="dark">
      <h3>Massage Specializations</h3>
      <p>For active adults who practice Crossfit, Lifting, Golf, Tennis, Pickleball, Running, Cycling, Swimming, Motocross and more.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
<Container>
	<Row>
		<Col>
			<Schedule />
		</Col>
	</Row>
</Container>
<Container>
	<Row>
		<Col className="x-large-text center-text">
			<a href="https://www.facebook.com/MassageNorthScottsdale/" className="mr-3"><FontAwesomeIcon icon={faFacebook} color="#A69897" /></a>
			<a href="https://www.instagram.com/explore/locations/731713500209404/massage-north-scottsdale/" className="mr-3"><FontAwesomeIcon icon={faInstagram} color="#A69897" /></a>
			<a href="https://goo.gl/maps/41JkASchmtvRjQqy5"><FontAwesomeIcon icon="map-marker-alt" color="#A69897" /></a>
		</Col>
	</Row>
</Container>
<Container>
	<Row>
		<Col>
			<img 
			src={abmpLogo}
			height="100"
        	className="center"
        	alt="Respect Massage"			
			/>
		</Col>
	</Row>
</Container>
</section>
    );
  }
}
 
export default Home;