import React from 'react';
import { Container, Row, Col, ButtonGroup, Button, Modal } from 'react-bootstrap';

function IncreasedPerformance() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Increased Performance</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Increased Performance</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				With consistent massage therapy, both range of motion and muscle flexibility improve. When the body moves properly, an athlete can move more efficiently.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function ImprovedRecoveryTime() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Improved Recovery Time</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Improved Recovery Time</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Massage therapy relaxes muscle tissue, which reduces inflammation and spasms.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function ImprovedCirculation() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Improved Circulation</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Improved Circulation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Massage facilitates circulation because pressure created by the massage actually moves blood through the congested areas. The release of this pressure causes new blood to flow into the muscle, which is needed to heal. 
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function RelieveMuscleTension() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Relieve Muscle Tension</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Relieve Muscle Tension</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Massage helps to relieve tension by stretching and increasing tissue elasticity. Thereby softening the tissue so muscle fibers can be loosened.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function ReducesFatigueAndStress() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Reduces Fatigue and Stress</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Reduces Fatigue and Stress</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				The physiological effects that most commonly occur during a massage include increased endorphins. An increase in endorphins serotonin and dopamine reduces stress.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}


class Benefits extends React.Component {
  render () {
    return (
    <section id="benefits">
      	<h5>Benefits of Massage</h5>
      	<Container>
      	<Row>
			<Col>
				<ButtonGroup vertical size="lg" className="center mw-30">
					<IncreasedPerformance />
					<ImprovedRecoveryTime />
					<ImprovedCirculation />
					<RelieveMuscleTension />
					<ReducesFatigueAndStress />
				</ButtonGroup>
			</Col>
		</Row>
		</Container>
    </section>
    );
  }
}

export default Benefits;