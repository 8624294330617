import React from "react";
import { Container, Row, Col, ButtonGroup, Button, Modal } from 'react-bootstrap';
import Schedule from './schedule';

function DeepTissue() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Deep Tissue</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Deep Tissue</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Deep tissue massage therapy is similar to Swedish massage but with deep tissue, your therapist applies slow firm pressure to release muscle tension. The focus is the deepest layers of muscle tissue, tendons and fascia.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function SportsMassage() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Sports Massage</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Sports Massage</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Therapeutic sports massage is a technique that focuses on soft tissue aches, pain and injuries that are associated with recreational activities. 
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function NeuromuscularMassage() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Neuromuscular Massage</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Neuromuscular Massage</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Neuromuscular massage therapy is a specialized form of manual massage in which digital pressure and friction are used to release trigger points or areas of strain. 
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function Cupping() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Cupping</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Cupping</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Cupping therapy is when a therapist applies special suction cups on your skin for a few minutes to promote healing. Known to increase blood flow and promote relaxation, as well as pain reduction. 
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function OrthopedicCervical() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Orthopedic Cervical and Spine</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Orthopedic Cervical and Spine</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				A type of injury rehabilitation massage that focuses on muscles and soft tissue surrounding joints. The aim is to relieve pain and to create a fuller range of motion.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function SwedishAndElder() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Swedish and Elder Touch</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Swedish and Elder Touch</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				A Swedish massage is designed to relax the entire body by rubbing the muscles in long, gliding strokes in the direction of blood returning to the heart.
				<br />Elder touch massage is a technique using gentle strokes with the purposes of relaxation and circulation.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function LymphaticDrainage() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Lymphatic Drainage Massage</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Lymphatic Drainage Massage</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				A Therapeutic massage that assists the flow of lymphatic thru your body which in turn can boost the immune system, reduce swelling and speed the healing process.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function MuscleScraping() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Muscle Scraping</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Muscle Scraping</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Instrument-assisted soft tissue mobilization, often referred to at the Graston technique or muscle scraping, can be effective in treating repetition injuries. Muscle scraping restarts the healing process and can relieve chronic pain, like tennis elbow or injuries often found in runners.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

function OncologyMassage() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    	<Button onClick={handleShow}>Oncology Massage</Button>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Oncology Massage</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Specialized approach to massage therapy for cancer patients.
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="secondary" onClick={handleClose}>
				Close
			  </Button>
			</Modal.Footer>
		</Modal>
    </>
  );
}

class Modalities extends React.Component {
render() {
  return (
	<section id="modalities">
		<h5>Modalities</h5>
		<Container>
			<Row>
				<Col>
					<ButtonGroup vertical size="lg" className="center mw-30">
						<DeepTissue />
						<SportsMassage />
						<NeuromuscularMassage />
						<Cupping />
						<OrthopedicCervical />
						<SwedishAndElder />
						<LymphaticDrainage />
						<MuscleScraping />
						<OncologyMassage />
					</ButtonGroup>
					<Schedule />
				</Col>
			</Row>
		</Container>
    </section>
    );
  }
}

export default Modalities;